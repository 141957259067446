import React from 'react';
import emailjs from '@emailjs/browser';
import Reaptcha from 'reaptcha';

// reactstrap components
import {
    Button,
    Input,
    InputGroup,
    Row,
    Col,
    Form,
    Alert,
    Container,
    Spinner
} from "reactstrap";

function ContactForm() {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [contactInfo, setContactInfo] = React.useState({
        name: "",
        email: "",
        message: "",
        to_name: 'info@senteursduciel.be',
        'g-recaptcha-response': ""
    });
    const [recaptchaToken, setRecaptchaToken] = React.useState("");
    const captcha = React.useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setDisabled(true);
        setSpinner(true);
        const params = {
            ...contactInfo,
            'g-recaptcha-response': recaptchaToken,
        };

        emailjs.send(
            'service_5q3hq9m',
            'template_156ghji',
            params,
            '0KrQ8zduccJM2vn-3'
        ).then(function (response) {
            setAlert(true);
            setSpinner(false);
            setContactInfo({ name: "", email: "", message: "", 'g-recaptcha-response': "" });
            captcha.current.reset();
        }, function (error) {
            console.log(error.text);
        });
    };

    const onVerify = (e) => {
        setRecaptchaToken(e);
        setDisabled(false);
    };

    return (
        <>
            <Container>
                <Form onSubmit={sendEmail}>
                    <Row>
                        <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                            <InputGroup
                                className={
                                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                                }
                            >
                                <Input
                                    name="name"
                                    placeholder="Naam..."
                                    value={contactInfo.name}
                                    type="text"
                                    onFocus={() => setFirstFocus(true)}
                                    onBlur={() => setFirstFocus(false)}
                                    onChange={(e) => {
                                        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
                                    }}
                                ></Input>
                            </InputGroup>
                            <InputGroup
                                className={
                                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                                }
                            >
                                <Input
                                    name="email"
                                    value={contactInfo.email}
                                    placeholder="Email..."
                                    type="text"
                                    onFocus={() => setLastFocus(true)}
                                    onBlur={() => setLastFocus(false)}
                                    onChange={(e) => {
                                        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
                                    }}
                                ></Input>
                            </InputGroup>
                            <div className="textarea-container">
                                <Input
                                    cols="80"
                                    name="message"
                                    value={contactInfo.message}
                                    placeholder="Typ uw bericht..."
                                    rows="4"
                                    type="textarea"
                                    onChange={(e) => {
                                        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
                                    }}
                                ></Input>
                            </div>
                            <div className="recaptcha">
                                <Reaptcha sitekey={process.env.REACT_APP_SITE_KEY} ref={captcha}
                                    onVerify={onVerify}></Reaptcha>
                            </div>
                            <div className="send-button">
                                <Button
                                    block
                                    className="btn-round"
                                    color="info"
                                    size="lg"
                                    type="submit"
                                    disabled={disabled}
                                >
                                    {spinner && <Spinner size="sm"></Spinner>}
                                    {spinner && ' '}Verzenden
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <div className="section section-notifications">
                    <Alert color="success" isOpen={alert}>
                        <Container>
                            Uw bericht is succesvol verzonden
                            <button
                                type="button"
                                className="close"
                                onClick={() => setAlert(false)}
                            >
                                <span aria-hidden="true">
                                    <img
                                        alt="Sluit de notificatie"
                                        src={require("assets/img/close.webp")}
                                    ></img>
                                </span>
                            </button>
                        </Container>
                    </Alert>
                </div>
            </Container>
        </>
    );
};

export default ContactForm;
