/*

=========================================================
* Now UI Kit React - v1.5.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/css/fontawesome.css";
import "assets/css/brands.css";

// fonts
import 'typeface-roboto';
// pages for this kit
import Index from "views/Index.js";
import Rasstandaard from "views/Rasstandaard";
import Werkstandaard from "views/Werkstandaard";
import Pups from "views/Pups";
import ANest from "views/ANest";
import BNest from "views/BNest";
import CNest from "views/CNest";
import Juno from "views/Juno";
import Qetesh from "views/Qetesh";
import Skadi from "views/Skadi";
import Horus from "views/in-memoriam/Horus";
import OverOns from "views/OverOns";
import Contact from "views/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/rasstandaard" element={<Rasstandaard />} />
        <Route path="/werkstandaard" element={<Werkstandaard />} />
        <Route path="/pups" element={<Pups />} />
        <Route path="/a-nest" element={<ANest />} />
        <Route path="/b-nest" element={<BNest />} />
        <Route path="/c-nest" element={<CNest />} />
        <Route path="/juno" element={<Juno />} />
        <Route path="/qetesh" element={<Qetesh />} />
        <Route path="/skadi" element={<Skadi />} />
        <Route path="/horus" element={<Horus />} />
        <Route path="/over-ons" element={<OverOns />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
);
