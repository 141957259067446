import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import CNestHeader from "components/Headers/CNestHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function CNest() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over ons derde nest Braque Français Type Pyrénées pups gefokt uit 2 werk- en schoonheidskampioenen."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <CNestHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Qetesh des Senteurs du Ciel x Nestor du Milobre de Bouisse</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p>Dekkingsdatum: 8/3/2023<br />
                  Geboortedatum: 8/5/2023<br />
                  Aantal reuen: 2<br />
                  Aantal teven: 4</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Qetesh des Senteurs du Ciel, Braque Français Type Pyrénées teef en moeder van ons derde nest"
                  src={require("assets/img/c-nest-2.webp")}
                ></img>
                <h3 className="title">Qetesh des Senteurs du Ciel</h3>
                <p>3/4/2017<br />
                  HD-A<br />
                  Frans werkkampioen op geschoten wild<br />
                  Belgian Junior Champion<br />
                  World Winner 2018</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Nestor du Milobre de Bouisse, Braque Français Type Pyrénées reu en vader van ons derde nest"
                  src={require("assets/img/c-nest-3.webp")}
                ></img>
                <h3 className="title">Nestor du Milobre de Bouisse</h3>
                <p>6/9/2017<br />
                  HD-B<br />
                  Trialer<br />
                  Frans werkkampioen op geschoten wild<br />
                  European Winner 2022<br />
                  France Winner 2022<br />
                  Champion de France de conformité au standard</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées teef rennend in het bos"
                  src={require("assets/img/c-nest-4.webp")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">C nest</h2>
                <h3 className="description">Het nest van Qetesh</h3>
                <p>Veel later dan gepland zijn we eindelijk zover dat we een eerste, en meteen ook laatste,
                  nest met Qetesh plannen. De Covid-19 pandemie heeft onze plannen danig in de war gebracht
                  maar eindelijk zijn we zover! We hebben heel wat kandidaten onderzocht en een aantal ook live
                  in Frankrijk aan het werk gezien. Uiteindelijk viel ons oog op Nestor du Milobre de Bouisse,
                  een prachtige reu, sterk gespierd, mooie kop, tonnen jachtpassie en een heerlijk zacht karakter.</p>
                <p>Bij een eerste poging in het najaar van 2022 had Qetesh ons verrast: ze bleek al veel verder
                  in haar cyclus te zijn dan we hadden verwacht en we waren dan ook te laat om haar te laten dekken!
                  Het was wachten tot begin maart 2023 alvorens ze terug loops werd. Deze keer waren we er vroeg bij
                  en lieten we op regelmatige basis haar progesteron-gehalte meten.</p>
                <p>In de ochtend van 7 maart gaf die waarde aan dat de eisprong net had plaats gevonden.
                  Vermits de eicellen vervolgens nog even moeten rijpen, zijn we pas in de ochtend van 8 maart 750km richting
                  Aquitanië gereden om haar dan in de avond te laten dekken.</p>
                <p>Na een 4-tal weken hadden we zelf al een sterk buikgevoel dat Qetesh zwanger was maar bevestiging 
                  via een echografie is altijd leuk: zo'n echo is zonder risico voor de pups en Qetesh is sowieso een hondje 
                  dat kalm blijft bij de dierenarts. En dus hadden we een echografie ingepland op 14/4. Bijna meteen zagen we de 
                  eerste foetus, altijd even een emotioneel moment! Nauwkeurig tellen via een echografie is niet eenvoudig 
                  maar onze dierenarts meende er toch zeker 6 geteld te hebben.</p>
                <p>Om echter een nauwkeuriger beeld te krijgen over het aantal te verwachten pups, hadden we ook al een afspraak 
                  gemaakt bij een gespecialiseerde dierenarts die over een hypermoderne RX scanner beschikt. Op dag 52 trokken
                  we met Qetesh naar de dierenarts waar ook via radiografie bevestigd werd dat we ons aan 6 pups mochten verwachten!
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées pups van 1 week oud"
                  src={require("assets/img/c-nest-5.webp")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p><br/>Maandag 8/5 was het dan zover en zagen we 's ochtends de eerste tekenen van de ontsluitingsfase 
                  (hijgen en trillen). Maar die fase kan tot wel 12 uur duren en dus was het wachten tot in de vroege 
                  avond: om 18:31 beviel Qetesh van een eerste reu, daarna volgden in snel tempo 2 teefjes en nog 1 
                  reutje waarna Qetesh even een uurtje pauze nam om tot slot nog 2 teefjes op de wereld te zetten. Tegen 
                  9 uur 's avonds zat de bevalling er op en lagen alle pups lekker warm bij mama onder de donkerstraler! 
                </p>
              </Col>
            </Row>
          </div>          
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default CNest;
