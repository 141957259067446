import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import HorusHeader from "components/Headers/in-memoriam/HorusHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Horus() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over Horus, onze eerste eigen hond, een Weimaraner reu die helaas net voor zijn 14de verjaardag is overleden."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <HorusHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Horus (28/2/2008 – 8/2/2022)</h2>
                <h3 className="description">In den beginne</h3>
                <h4 className="description">Hoe Horus in ons leven kwam</h4>
                <p>Eens onze wildere (post-)studentenjaren achter de rug waren, begon het bij ons beiden
                  te kriebelen om een hond aan te schaffen. Zoals bij zovelen ging daar een uitgebreide
                  zoektocht aan vooraf: we voelden ons echter wel al snel aangetrokken tot de jachthonden
                  en dan meerbepaald ofwel een retriever of een staande hond.</p>
                <p>Van de jacht en alles wat daar bij hoort, wisten we toen nog niets maar we waren
                  beiden actieve buitenmensen en wilden dan ook een actieve hond die daar deel van kon uitmaken.</p>
                <p>Uiteindelijk viel de keuze op ofwel een Labrador Retriever ofwel een Weimaraner Korthaar.
                  We hadden wel een beetje schrik van sommige beschrijvingen over de Weimaraner waarbij we toch meermaals
                  tegen kwamen dat dit ras niet meteen als “beginnershond” wordt aangeraden. Initieel gingen we dan ook op zoek naar
                  een Labrador Retriever. Maar na een negatieve ervaring met een Labrador fokker,
                  kwamen we onderling tot besluit dat we toch voor een Weimaraner zouden gaan en dat we ons uiterste best gingen doen
                  om dat allemaal in goede banen te leiden. Na wat zoekwerk kwamen we in contact met een fokker die nog een
                  laatste pup had en na een eerste kennismaking mochten wij ons de nieuwe eigenaars van een heerlijke pup
                  genaamd Horus noemen!</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Een Weimaraner reu genaamd Horus kijkt uit over de Oostenrijkse Alpen"
                  src={require("assets/img/horus-3.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Het bleek al snel dat wat we hadden gelezen over Weimaraners zeker niet overdreven was!
                  We moeten het heel eerlijk zeggen: wij hebben de eerste maanden veel mooie momenten met Horus
                  gehad maar ook enorm afgezien. Horus was namelijk dolgraag bij ons maar had een absolute bloedhekel
                  aan alleen zijn. We deden alles netjes volgens het boekje en bouwden het alleen zijn stap voor
                  stap op maar het mocht allemaal niet baten: Horus krijste letterlijk het hele gebouw bij elkaar
                  (wij leefden toen nog in een loftcomplex).</p>
                <p>Alles hebben we geprobeerd maar allemaal tevergeefs. Tot we uiteindelijk eens een oud en versleten
                  Ikea-kussen in zijn puppyren achterlieten. Daar ging hij meteen op liggen en warempel: hij bleef stil!
                  We gingen er beiden van uit dat dat kussen ongetwijfeld binnen de kortste keren uit elkaar gescheurd zou worden
                  (ja, er zijn die eerste maanden ook wel aardig wat dingen gesneuveld) maar nee hoor: het Ikea-kussen was
                  de magische oplossing!</p>
                <p>Voor zijn eerste verjaardag kreeg Horus dan ook een echt hondenkussen waar hij zich lekker in
                  kon nestelen en zo hebben we ondertussen dan ook een hele collectie van (4) hondenkussens want ook onze
                  Braques vinden het heerlijk om zich hier in te nestelen.</p>
                <p>Dankzij Horus’ fokker en vooral de eigenaar van Horus z’n vader, werden wij ook aangemoedigd om met
                  Horus aan jachttraining te gaan doen. We hadden tenslotte een energieke jachthond in huis gehaald en
                  die moest natuurlijk zijn energie kwijt geraken! En zo zette Horus ons dan ook eerst op het wedstrijdpad en
                  daarna ook op het jachtpad…</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Een Weimaraner reu genaamd Horus op een jachtwedstrijd tijdens de apport proef"
                  src={require("assets/img/horus-4.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="description">Van wedstrijdspeler tot jager</h3>
                <h4 className="description">Hoe Horus ons heeft leren jagen</h4>
                <p>Al snel bleek dat Horus de training (na het schot) heerlijk vond en we oefenden dan ook
                  zeer regelmatig (zeg maar quasi dagelijks). Daardoor maakten we natuurlijk ook mooi vorderingen
                  en dus werd er al snel geopperd om dan ook maar eens aan wedstrijden deel te nemen. En eens we
                  daar de smaak van te pakken hadden, was er geen weg terug meer…</p>
                <p>Horus ging eerst aan de slag op de Belgische africhtingsproeven maar al snel weken we uit
                  naar Nederland waar deze tak van de jachthondensport uitgebreider is. We liepen al snel mee op wat
                  men ondertussen de SJP (Standaard Jacht Proeven) noemt en dankzij de goede resultaten die daar behaald werden,
                  konden we ook aan de MAPs (Meervoudige Apporteer Proeven) gaan deelnemen.</p>
                <p>Enkel het allerhoogste niveau (het A-niveau) bleek niet aan ons besteed te zijn. Met name de
                  dirigeerproef was iets waar Horus en ik altijd afwijkende ideeën over hadden. Vermits ik na verloop van
                  tijd ook zag dat Horus dat eindeloze oefenen op dirigeren hartstikke beu was, heb ik toen het wedstrijd lopen
                  met Horus stop gezet. Het moet tenslotte wel voor beiden leuk blijven!</p>
                <p>Ondertussen hadden we echter ook al enkele keren de gelegenheid gehad om mee op jacht te gaan om daar
                  als tracker/picker-up dienst te kunnen doen en zo kregen we natuurlijk dan ook de smaak van de jacht te pakken.
                  De ganse opleiding (theorie + praktijk) werd aangevat en na 2 jaar had ik m’n jachtverlof op zak
                  (ja: het eerste jaar had ik ten gevolge van de zenuwen een fout gemaakt in “de hut” en was ik de eerste keer dus niet geslaagd).</p>
                <p>En zo begon dan ook weer een heel ander avontuur waar zowel Horus als onze Braques een hoofdrol in spelen…</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Horus;
