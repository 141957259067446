import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import BNestHeader from "components/Headers/BNestHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function BNest() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over ons tweede nest Braque Français Type Pyrénées pups gefokt uit 2 werk- en schoonheidskampioenen."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <BNestHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Juno du Vallon de Beaudini x Loustic des Remises d'Arconie</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p>Dekkingsdatum: 13/3/2019<br />
                  Geboortedatum: 12/5/2019<br />
                  Aantal reuen: 5<br />
                  Aantal teven: 5</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Juno de Vallon de Beaudini, Braque Français Type Pyrénées teef en moeder van ons eerste nest"
                  src={require("assets/img/b-nest-2.webp")}
                ></img>
                <h3 className="title">Juno du Vallon de Beaudini</h3>
                <p>29/5/2014<br />
                  HD-B/HD-A (België/Frankrijk)<br />
                  Frans Werkkampioen najaar (fazant)<br />
                  Belgisch Showkampioen<br />
                  Nederlands Kampioen<br />
                  Internationaal Showkampioen<br />
                  Internationaal Schoonheidskampioen</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Loustic des Remises d'Arconie, Braque Français Type Pyrénées reu en vader van ons tweede nest"
                  src={require("assets/img/b-nest-3.webp")}
                ></img>
                <h3 className="title">Loustic des Remises d'Arconie</h3>
                <p>2/7/2015<br />
                  HD-B<br />
                  Trialer<br />
                  Internationaal Werkkampioen</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées teef rennend over het water"
                  src={require("assets/img/b-nest-4.webp")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">B nest</h2>
                <h3 className="description">Het tweede en laatste nest van Juno</h3>
                <p>Twee jaar na haar eerste nest, was het tijd voor Juno’s tweede en laatste nest.
                  Vermits we heel tevreden zijn over hoe de pups uit de combinatie Juno x Gibus zijn uitgegroeid,
                  hadden we natuurlijk gewoon een herhaalcombinatie kunnen doen maar dat idee hebben we al direct afgeschoten.
                  Het is voor ons namelijk ook belangrijk om voldoende diversiteit binnen het ras te behouden en vermits
                  Gibus reeds heel wat nageslacht heeft, gingen wij dan ook op zoek naar een andere reu.</p>
                <p>We hebben het geluk dat in thuisland Frankrijk heel wat capabele reuen rondlopen en vermits ik daar
                  ook zelf met Juno wedstrijd loop, heb ik het genoegen gehad om heel wat van deze honden in actie te zien.
                  Maar een goed werkende reu is natuurlijk niet het enigste dat telt. Reu en teef mogen niet te nauw genetisch
                  verwant zijn (opnieuw die genetische diversiteit die wij zo belangrijk vinden) en we hadden bij voorkeur ook
                  graag een reu gehad die nog niet al té veel nakomelingen heeft rondlopen.</p>
                <p>Zo kwamen wij dan ook eind 2018 uit bij Loustic des Remises d’Arconie. Een donkerbruine, kleinere reu met
                  een uitstekende morfologie en dat heerlijke zachte karakter dat zo typerend is voor de Braque Français.
                  Daarnaast ook een schitterende werkhond zowel op wedstrijd als in de jacht met als specialiteit de snip.</p>
                <p>We hadden verwacht dat Juno ergens eind december/begin januari loops zou worden, maar ze stelde het
                  deze keer uit tot in maart. De natuur kan je nu eenmaal niet dwingen maar toen de progesteron-bepaling
                  aangaf dat het tijd was, trokken we op 13 maart 2019 richting Normandië!</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Moeder Juno du Vallon de Beaudini waakt over haar 10 slapende pups"
                src={require("assets/img/b-nest-5.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>We hadden dan al wel enige ervaring met het eerste nest maar die zenuwen blijven toch aanwezig.
                  Hoe zou de dekking deze keer verlopen? Al snel bleek dat dit even vlot ging als vorige keer want het
                  duurde nog geen kwartiertje of Juno en Loustic gingen over tot een mooie, natuurlijke dekking.</p>
                <p>In tegenstelling tot vorige keer en omdat haar progesteron waarden reeds veel hoger waren dan bij het
                  eerste nest, gingen we deze keer voor een eenmalige dekking. Na een lekkere lunch met de eigenaar van Loustic,
                  begonnen we weer aan onze rit huiswaarts.</p>
                <p>Zo’n 680 kilometer rijden naar Normandië, dekking + lunch en dan weer zo’n 680 kilometer naar huis!
                  Je moet er wat voor over hebben als fokker en dan nog was het natuurlijk afwachten of de dekking succesvol was.
                  Na enkele weken zagen we echter al een aantal veranderingen en toen Juno plots ook minder trek in haar brokken begon te
                  krijgen (die ze anders altijd met veel plezier opeet), wisten we het: Juno is drachtig!</p>
                <p>Niet veel later begonnen we ook duidelijk fysieke veranderingen te zien en werd Juno’s buikje steeds ronder.
                  Hij werd zo rond dat wij wel heel nieuwsgierig werden hoeveel pups ze zou dragen. Nu nemen wij altijd enkele dagen voor
                  de bevalling een scan om beter voorbereid te zijn op eventuele complicaties. En op zo’n scan kan je dan ook meteen
                  het aantal pups tellen: we telden maar liefst 8 pups maar het zou weldra blijken dat Juno een verrassing voor ons in petto had!</p>
                <p>Op zondag 12 mei was het zover en begon Juno aan de bevalling. De eerste pup, een flinke reu, werd geboren om 09:07
                  terwijl de laatste, een fijn teefje, geboren werd om 03:52. Het waren er echter geen 8 zoals we op de scan
                  meenden te zien maar maar liefst 10 in totaal: 5 teefjes en 5 reutjes! Met een tienling had mama veel werk
                  maar samen met onze ondersteuning deed Juno het uitstekend en al snel lagen alle pups warm en droog aan de melkbar.</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default BNest;
