import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SkadiHeader from "components/Headers/SkadiHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Skadi() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over Skadi des Senteurs du Ciel, teefje uit het tweede nest en onze eerste sterk bruin gespikkelde Braque Français Type Pyrénées."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <SkadiHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Skadi des Senteurs du Ciel</h2>
                <h3 className="description">In resultaten</h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Veldwerk</h4>
                <p>Voorlopig geen resultaten</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <img
                  alt="Skadi des Senteurs du Ciel, Braque Français Type Pyrénées teef komt afgestormd"
                  src={require("assets/img/skadi-2.webp")}
                ></img>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Schoonheid</h4>
                <p>Voorlopig geen resultaten</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Skadi des Senteurs du Ciel</h2>
                <h3 className="description">In woord en beeld</h3>
                <h4 className="description">Onze eerste generatie</h4>
                <h5 className="description">Waarom we Skadi hebben gekozen</h5>
                <p>Na de ervaringen met het eerste nest dachten we dat het nu wel gemakkelijker zou zijn om een teefje
                  te kiezen uit het tweede nest. Dat was echter voordat er maar liefst 10 pups, waarvan 5 teefjes,
                  in het tweede nest geboren werden!</p>
                <p>Vermits de vader, Loustic des Remises d’Arconie, een bruinschimmel was, hadden we niet enkel overwegend
                  witte pups maar ook een aantal overwegend bruine met maar liefst 3 van de 5 teefjes waarvan al snel
                  duidelijk was dat die zich tot bruinschimmels gingen ontwikkelen. Nu is kleur niet meteen ons belangrijkste
                  selectiecriterium en eigenlijk vond (en vind ik nog steeds) witte exemplaren gemakkelijker terug te vinden in
                  het veld en al helemaal in het bos.</p>
                <p>Maar het bleek wel al snel dat er in die bruine teefjes heel wat pit zat. De witte exemplaren lieten zich
                  zeker ook niet doen maar er zat duidelijk toch wat meer peper in “de brownies” zoals we het bruine groepje
                  (er was ook nog 1 bruin reutje) hadden genoemd. En een pittig karaktertje is dus wel al een selectiecriterium voor
                  ons.</p>
                <p>Maar hoe zat het met de jachtaanleg? Toch nog steeds het belangrijkste criterium al is het niet gemakkelijk om
                  daar op die jonge leeftijd al veel over te zeggen. Net als bij het eerste nest, trokken we er rond een week of zes al
                  eens mee naar een nabijgelegen veld om eens te zien hoe ze zich gedragen wanneer ze voor het eerst “in de grote natuur”
                  losgelaten worden (met mama Juno erbij natuurlijk). Je ziet dan toch meteen diegene die wat terughoudender zijn
                  maar ook altijd wel enkele exemplaren die het meteen allemaal geweldig vinden en dus wel eens durven uit het oog
                  verliezen waar de rest zich bevindt. Prima voor ons: dat zijn diegene waar we onze aandacht op vestigen.</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Skadi des Senteurs du Ciel, Braque Français Type Pyrénées, aan het jagen in Zweden"
                src={require("assets/img/skadi-3.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Zo rond 7 weken doen we dan graag een test met een kwarteltje in de tuin. Niets beter dan een vogeltje tenslotte
                  om de kwaliteiten van een “vogelhond” in te schatten. Alleen moet je wel oppassen met automatisch te
                  concluderen dat een pupje dat weinig interesse toont dan geen jachtaanleg zou hebben. Het kan namelijk
                  ook nog steeds zijn dat het pupje nog “wakker” moet worden. Wat we echter liever niet willen zien is een
                  pupje dat ofwel duidelijk bang dan wel totaal geen enkele interesse heeft.</p>
                <p>Over bange pups moesten we ons in ieder geval ook in dit nest geen zorgen maken. En een compleet gebrek aan
                  interesse was er ook deze keer niet bij. De ene was aanvankelijk wat terughoudender, de andere vloog er direct op af.
                  Weer een andere zag het vogeltje niet meteen maar als we ze even onder de wind brachten, zag je wel meteen dat neusje
                  in de richting van het vogeltje draaien. Prachtig om te zien!</p>
                <p>Maar het maakte de keuze er niet makkelijker op. Na 7 weken notities nemen in ons puppy-dagboek,
                  lazen we bij eentje (onder meer) “heel actieve teef”, “ontsnappingsartiest”, “opnieuw ontsnapt”,
                  “domineert broers en zussen”, “heel fel op kwartel”, “actief veld in”, “bemoeial”, “mondig”, “zelfstandig” en
                  meer van dat. Dat pupje was Skadi. Gegeven die notities, leek het ons het beste om haar zelf te houden want
                  dit konden we onze kandidaat-pupkopers niet aandoen.</p>
                <p>Het is later inderdaad gebleken dat Skadi een hele handvol is maar het is tegelijkertijd ook een heerlijk hondje.
                  Ontzettend actief en uiterst “birdy” buiten in het veld en in het bos en heer en meester binnenshuis (jawel,
                  de jongste is over het algemeen de baas al vermoed ik dat de anderen vooral zoiets hebben van
                  “laat je ons met rust als we je laten doen?”). Maar tegelijkertijd ook schattig en lief…wanneer ze er zin in heeft.</p>
              </Col>
            </Row>
          </div>          
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Skadi;
