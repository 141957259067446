import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ContactHeader from "components/Headers/ContactHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import ContactForm from "components/ContactForm.js";
import SEO from "components/SEO.js";

function Contact() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Gebruik deze pagina als u vragen hebt en graag contact met ons wil opnemen via mail of telefoon!"
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <ContactHeader />
        <Container>
        <div className="section section-contact-us text-center">          
            <h2 className="title">Vragen?</h2>
            <p className="description">Contacteer ons</p>       
        </div>
        <ContactForm/>
        <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>U kan ons ook bereiken op +32 486 296 404 of door rechtstreeks een mail naar info@senteursduciel.be 
                  te sturen. Vermits een bezoek verplicht is vooraleer u op onze wachtlijst geplaatst 
                  wordt, geven we ook al even mee dat wij in Herentals gevestigd zijn.</p>                
              </Col>
            </Row>
          </div>
          </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Contact;
