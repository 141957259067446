import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import JunoHeader from "components/Headers/JunoHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Juno() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over Juno du Vallon de Beaudini, ons eerste Braque Français Type Pyrénées teefje waarmee ons avontuur als fokker begon."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <JunoHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Juno du Vallon de Beaudini</h2>
                <h3 className="description">In resultaten</h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Veldwerk</h4>
                <h5 className="description">Titels</h5>
                <p>Trialer<br />
                  Frans werkkampioen op geschoten wild</p>
                <h5 className="description">CACT/RCACT</h5>
                <h6 className="description">Nationaal kampioenschapspunt</h6>
                <p>2 CACT (Frankrijk)<br />
                  1 RCACT (België)</p>
                <h5 className="description">Uitmuntend</h5>
                <p>2 in amateur (België)<br />
                  3 in open (België)<br />
                  3 in open solo – geschoten wild (Frankrijk)<br />
                  1 in open koppel – geschoten wild (Frankrijk)</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <img
                  alt="Juno de Vallon de Beaudini, Braque Français Type Pyrénées teef poserend met 1 van haar vele bekers"
                  src={require("assets/img/juno-2.webp")}
                ></img>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Schoonheid</h4>
                <h5 className="description">Titels</h5>
                <p>Belgisch Jeugdkampioen 2015<br />
                  Amsterdam Winner 2015<br />
                  European Winner 2016<br />
                  Belgisch Showkampioen<br />
                  Internationaal Showkampioen (CIE)<br />
                  Internationaal Schoonheidskampioen (CIB)<br />
                  Nederlands kampioen<br />
                  Belgisch Schoonheidskampioen</p>
                <h5 className="description">CACIB</h5>
                <h6 className="description">Internationaal kampioenschapspunt</h6>
                <p>8 (België, Nederland en Frankrijk)</p>
                <h5 className="description">CAC</h5>
                <h6 className="description">Nationaal kampioenschapspunt</h6>
                <p>8 (België, Nederland en Frankrijk)</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Juno du Vallon de Beaudini</h2>
                <h3 className="description">In woord en beeld</h3>
                <h4 className="description">In den beginne</h4>
                <h5 className="description">Hoe de Braque Français in ons leven kwam</h5>
                <p>Juno du Vallon de Beaudini is eigenlijk het resultaat van de zoektocht naar een
                  hond om als beginneling mee de wereld van het veldwerk te kunnen verkennen. De Braque Français
                  Type Pyrenées kwam dan ook niet zomaar ons leven binnen maar werd zorgvuldig gekozen omwille van
                  de (relatief) gemakkelijke trainbaarheid van het ras alsook de hoge mate waarin dit ras in
                  Frankrijk nog in handen is van kleinwildjagers en dan ook specifiek gefokt wordt om te
                  kunnen werken op de velden (waar ook de wedstrijden worden gehouden).</p>
                <p>Eens het ras gekozen was, kon de zoektocht naar een goede fokker beginnen en kwamen
                  wij al snel bij “Vallon de Beaudini” terecht die op topniveau mee draait in de Franse veldwerkwereld.
                  Met Falcom du Vallon de Beaudini hadden zij een prachtige reu (werk- en showkampioen) in handen en
                  met Eglantine du Vallon de Beaudini een zo mogelijk nog betere teef (eveneens werk- en showkampioen).</p>
                <p>Toen ik dan vernam dat Eglantine een eerste én meteen ook laatste nest zou produceren en dat Falcom
                  de vader zou zijn, was de knoop snel doorgehakt en werd er een teefje gereserveerd: dat teefje werd
                  Juno du Vallon de Beaudini!</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Juno du Vallon de Beaudini, Braque Français Type Pyrénées, toen ze nog pup was"
                src={require("assets/img/juno-3.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Wachten tot de geboorte, wachten tot we ze mochten gaan halen, 900 kilometer rijden en
                  dan nog een overnachting waar geen einde leek aan te komen. Maar daarna mochten we Juno voor
                  het eerst vast houden, wat een heerlijk moment!</p>
                <p>Iedereen die ooit al een pup gehad heeft, weet dat dat heerlijke tijden zijn die weliswaar ook op tijd
                  en stond worden afgewisseld door minder heerlijke momenten. De salontafel en één van de zetels
                  werden het slachtoffer van scherpe puppy-tanden maar verder ontpopte Juno zich al snel
                  tot een gemakkelijke pup.</p>
                <p>Die eerste maanden werd er veel gespeeld en al spelend geleerd maar wat gehoorzaamheid
                  betrof was op dat moment enkel “kom” en “zit” belangrijk. Leren was in die tijd vooral
                  mee het veld in en in contact komen met (eerst) kwarteltjes en dan patrijzen en
                  tot slot (veel later pas) fazanten.</p>
                <p>Juno vond het allemaal heerlijk en ontwikkelde zich dan ook tot een dolenthousiaste jeugdige
                  jachthond die altijd op zoek was naar vogels (zoals ze zo mooi in het Engels zeggen:
                  ze werd helemaal “birdy”).</p>
                <p>De eerste voorzichtige stappen werden gezet in de showwereld en, zoals u hierboven reeds
                  kon lezen, met resultaat. Ook de eerste jeugdwedstrijden in het veldwerk boden zich al snel aan en
                  het werd al snel duidelijk dat dat was waar haar passie lag.</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Juno du Vallon de Beaudini, Braque Français Type Pyrénées, aan de kust"
                src={require("assets/img/juno-4.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="description">Kleine meisjes worden groot</h3>
                <h4 className="description">Gezinshond én wedstrijdhond</h4>
                <p>Juno bleek al snel goed in staat te zijn om zowat iedereen rond haar pootjes te draaien:
                  de kinderen van mijn zus alsook de rest van de familie, onze vrienden, kennissen en ook
                  compleet onbekende mensen die haar pad kruisten. En natuurlijk ontsnapten ook wij daar
                  niet aan. Ze ontpopte zich dan ook tot een zéér sociale hond die graag geniet van aandacht en,
                  als ze dat niet genoeg krijgt, zelf even haar kopje op je been komt leggen om je
                  dan met een zielige blik aan te kijken.</p>
                <p>Ook onze Weimaraner – Horus – moest er aan geloven. Was hij in het begin niet vies van haar
                  op haar plaats te zetten, ondertussen laat hij haar met zijn speelgoed spelen,
                  tolereert hij dat ze bij het spelen op wandeling zijn speelgoed afpakt en gebeurt het zelfs
                  al eens dat hij zijn slaapplaats aan haar afstaat. Enkel als iets hem écht niet aanstaat
                  merken we dat hij nog steeds de (honden)baas in huis is.</p>
                <p>Binnenshuis werd ze, nadat de puppystreken achter de rug waren, een rustige hond zolang er
                  natuurlijk voldoende buitenshuis met haar gedaan werd. Er werd (en wordt nog steeds) dan ook
                  uitgebreid gewandeld, de veldwerktraining werd opgevoerd en er werd steeds meer verwacht op
                  vlak van gehoorzaamheid (naast “zit” en “kom” komen er ook andere commando’s in het spel
                  zoals “blijf”, “volg” en zeker niet te vergeten: “apport” maar daar moet dan eerst weer “vast” en “los”
                  voor aangeleerd worden).</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Juno du Vallon de Beaudini, Braque Français Type Pyrénées, aan het jagen op fazant in een bietenveld"
                src={require("assets/img/juno-5.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Omdat we ondertussen zo compleet ingepalmd waren door Juno en al heel wat andere
                  gelijkaardige soortgenoten (zowel qua karakter als qua werklust) hadden leren kennen
                  dankzij onze deelnames aan zowel show- als veldwedstrijden in Frankrijk, groeide het
                  idee om zelf een kennel te starten met dit prachtige ras.</p>
                <p>Juno zou de stammoeder van onze kennel worden maar daarvoor wilden we wel eerst
                  weten of ze ook boven het jeugdniveau in het veldwerk kon uitstijgen. Daar hadden we wel al
                  wat succes gehad maar het wordt pas écht moeilijk vanaf de amateur klasse
                  (in Nederland noemt men dit de novice klasse) en zelfs die klasse is maar een tussenstap naar
                  de open klasse (of kampioensklasse zoals men in Nederland zegt).</p>
                <p>In het voorjaar van 2016 gooide loopsheid roet in het eten en bakte ze er niet veel van maar
                  in het najaar mochten we in 1 weekend naar huis met twee maal een eerste plaats Uitmuntend in 2 amateurwedstrijden!
                  Het harde werk begon z’n vruchten af te werpen en Juno geraakte steeds beter onder controle zonder dat haar
                  passie daaronder te lijden had.</p>
                <p>En daarmee stond het vast: kennel “des Senteurs du Ciel” was geboren en Juno du Vallon de Beaudini zal
                  de solide basis vormen waarop wij aan dit nieuwe avontuur beginnen!</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Juno;
