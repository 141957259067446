import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import OverOnsHeader from "components/Headers/OverOnsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function OverOns() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over de eigenaars achter Braque Français Type Pyrénées jachthondenkennel Senteurs du Ciel."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <OverOnsHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Steve</h2>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Jonge Braque Français Type Pyrénées aan het werk"
                src={require("assets/img/over-ons-2.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Steve is bijna net even energiek als onze jachthonden en is dan ook diegene die er voor zorgt dat
                  ze dagelijks hun energie kwijt geraken. Dat betekent veel wandelen in de Herentalse bossen tijdens de
                  week terwijl er in het weekend vaak op verplaatsing gewandeld wordt langsheen de kust, langs velden, …</p>
                <p>Daarnaast ontfermt hij zich ook over de opvoeding en de training van de honden. Onder de opvoeding verstaan we
                  alles wat nodig is om van de hond een aangename levensgezel te maken die zich zowel binnen- als buitenshuis gedraagt.
                  Onder de training verstaan we in eerste instantie de elementaire gehoorzaamheid gevolgd door een doorgedreven jachttraining.</p>
                <p>Daarnaast is Steve ook competitief ingesteld en dus ook diegene die met de honden deelneemt aan wedstrijden.
                  Hij heeft daarbij een voorkeur voor de veldwedstrijden en spendeert zeer veel tijd, kilometers en ja ook bloed,
                  zweet en de occasionele traan aan het trainen van zijn honden door ze zoveel mogelijk onder wild te brengen.
                  Veerwild is tenslotte de leraar, de hond de leerling en het baasje gewoon een trotse “papa” die vanop een
                  afstand toekijkt en de hond aanmoedigt (ok, er komt wel net iets meer bij kijken dan enkel toekijken maar het
                  blijft een feit dat het de vogel is die de hond de elementaire regels van het spelletje met de wind aanleert).</p>
                <p>Ook het showgebeuren wordt door Steve afgehandeld: als fokker is het tenslotte belangrijk om de bouw van je
                  hond objectief en bij voorkeur door meerdere mensen met kennis van zake te laten beoordelen.  Een correcte bouw en
                  een uiterlijk conform de rasstandaard zijn tenslotte ook belangrijk om in het veld en op de jacht goed te kunnen presteren.</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Sook</h2>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Braque Français Type Pyrénées aan het werk in een bietenveld waar de hond succesvol tot voorstaan is gekomen en een fazant net opvliegt"
                src={require("assets/img/over-ons-3.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Sook is diegene die er voor zorgt dat aan het thuisfront alles reilt en zeilt zoals het hoort.
                  Dat wil zeggen: zij voert alle ondersteunende “diensten” uit. Vermits Steve meestal buitenshuis aan de slag is met de honden,
                  zorgt zij er voor dat binnenshuis alles in orde is.</p>
                <p>Een punt waar wij zéér veel aandacht aan besteden en dat wij als fokker van cruciaal belang vinden is een uitstekende
                  hygiëne. Sook is diegene die erop toekijkt dat alles proper blijft en, wanneer er pups geboren worden, betekent
                  dit dagelijks verversen, wassen, opkuisen, …</p>
                <p>Daarnaast is Sook ook diegene waar je als kandidaat puppy-koper het meest mee in contact zult komen.
                  Wij vinden het tenslotte zéér belangrijk dat onze pups in een goede thuis terecht komen. Nee, u hoeft
                  zich geen zorgen te maken: u dient bij ons geen toelatingsexamen of iets dergelijks af te leggen.
                  Maar wij gaan wel uitgebreid met u praten om te zien of het “klikt”.</p>
                <p>En als het klikt dan zijn wij ook diegenen die gaan kijken welke pup het beste bij u past. Dat betekent
                  dus dat u wel het gewenste geslacht kunt kiezen maar niet welke pup precies. Tenslotte leven wij 8 weken
                  dag in dag uit samen met de pups en kunnen wij dus het beste inschatten welke pup qua temperament en karakter
                  het beste bij een bepaalde kandidaat past.</p>
                <p>Tot slot speelt Sook ook een belangrijke rol bij de initiële socialisatie van de pups waarbij ze deze blootstelt aan
                  alle dagdagelijkse geluiden die in een typisch huishouden verwacht kunnen worden (binnen- en buitenshuis) en kijkt ze er
                  ook op toe dat alles correct verloopt wanneer er mensen komen puppy-knuffelen (eveneens een belangrijk onderdeel van de
                  socialisatie maar er dient wel op gelet te worden dat de jonge pups niet overweldigd
                  geraken en – met name door kinderen – correct behandeld worden).</p>
              </Col>
            </Row>
          </div>

        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default OverOns;
