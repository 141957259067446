import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PupsHeader from "components/Headers/PupsHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Pups() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <SEO
                title="Jachthondenkennel Senteurs du Ciel"
                description="Als u graag een Braque Français Type Pyrénées pup wenst, raden wij u sterk aan om eerst deze pagina met puppy informatie te lezen!"
                type="article" />
            <IndexNavbar />
            <div className="wrapper">
                <PupsHeader />
                <Container>
                    <div className="section-story-overview">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Pupinfo</h2>
                                <h3 className="description">Is een Braque Français pup iets voor mij?</h3>
                                <p>Een pup ziet er natuurlijk superleuk uit en het duurt ongetwijfeld slechts enkele seconden voor
                                    uw hart smelt maar een pup is zeer snel een hond en een hond is, zoals men zegt, niet voor even
                                    maar voor een gans leven. Alvorens u beslist om een Braque Français pup in huis te halen,
                                    doet u er goed aan om even aan het volgende te denken:</p>
                                <p>De Braque Français is een zéér energieke jachthond die daarenboven zéér aanhankelijk is. Dit heeft een aantal gevolgen:</p>
                                <p>de eerste 2 weken dat u de pup in huis haalt bent u zelf ook thuis om de pup (onder andere) aan te
                                    leren om alleen thuis te zijn zodat hij/zij daarmee overweg kan als u terug aan het werk gaat</p>
                                <p>het is heel fijn als u een grote tuin hebt maar als u niet met de pup mee die tuin ingaat om met hem/haar
                                    te spelen, moet u niet verwachten dat de pup in die tuin zijn energie zal kwijtraken. Hij zal eerder aan de
                                    deur op u staan wachten. Of uit verveling uw tuin gaan herbouwen.</p>
                                <p>als u het nog niet bent, zal u binnen de kortste tijd een fervent wandelaar/jogger zijn die dagelijks met
                                    de pup/hond op stap gaat. We hebben het daarbij niet over een blokje rond maar over mooie, ruime wandelingen.
                                    U zit er niets mee in om wat vroeger op te staan zodat u de hond eerst nog kan uitlaten alvorens u gaat werken
                                    en als u ‘s avonds thuis komt van een harde dag werken, kijkt u er al naar uit om er een stevige avondwandeling
                                    mee te doen. Bij voorkeur los indien mogelijk. En niet enkel wanneer de zon schijnt, maar ook als het regent,
                                    sneeuwt en vriest.</p>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <img
                                alt=" Braque Français Type Pyrénées puppy"
                                src={require("assets/img/pups-2.webp")}
                            ></img>
                        </Col>
                    </Row>
                    <div className="section-story-overview">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <p>pups ontdekken de wereld met hun tanden: één onbewaakt moment kan volstaan voor een pup om uw gloednieuwe
                                    lederen zetel van zijn eigen tandafdrukken te voorzien.</p>
                                <p>pups worden niet automatisch geboren met het instinct om buitenshuis hun behoeften te doen. Zeker in het begin
                                    kan u zich nog wel eens aan een ongelukje verwachten en dat mooie tapijt van u is daarvoor dé best geschikte plaats.</p>
                                <p>als u dacht er met dagelijkse (lange) wandelingen vanaf te komen dan moet ik u teleurstellen:
                                    uw pup is een werkhond en zal dan ook een “job” nodig hebben. Wij zijn als fokker zeer blij indien dit
                                    de jacht blijkt te zijn maar dat is zeker niet noodzakelijk: er zijn ook tal van leuke, actieve hondensporten
                                    zoals agility en canicross die uw hond de nodige voldoening zullen geven.</p>
                                <p>een pup wordt niet opgevoed geboren. Als fokker geven wij uw pup een zo goed mogelijke start de eerste
                                    8 weken (of 15 als u in het buitenland leeft), maar daarna dient u deze opvoeding verder te zetten.
                                    Niet voor enkele weken of maanden maar voor de rest van uw hond zijn leven.</p>
                                <p>de Braque Français is een zéér gevoelige hond. Zij reageren zéér slecht op een harde aanpak. Als u van de oude
                                    stempel bent en vindt dat een hond hardhandig duidelijk moet gemaakt worden wie er de baas is, raden wij u dit
                                    ras sterk af! Bent u echter iemand die zijn/haar hond als een volwaardig lid van het gezin beschouwt
                                    (zonder er echter een kind van te maken) en bent u bereid om uw hond op een duidelijke maar zachte manier te
                                    leren wat wel en niet kan, dan is de Braque Français wellicht iets voor u.</p>              </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <img
                                alt="Braque Français Type Pyrénées puppy"
                                src={require("assets/img/pups-3.webp")}
                            ></img>
                        </Col>
                    </Row>
                    <div className="section-story-overview">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <p>de Braque Français is en blijft een jachthond zelfs als u er nooit mee gaat jagen. Hij/zij zal
                                    sowieso vroeg of laat achter wild aan gaan: allerhande vogels, konijnen en ja, als u het “geluk”
                                    hebt om nabij een bos met reeën te wonen, zelfs achter reeën. U kan dit zeker onder controle krijgen
                                    maar niet meteen vanaf de eerste dagen/weken/maanden dat u uw nieuwe pup in huis haalt. U begint echter
                                    vanaf dag 1 met “kom” aan te leren zodat de jonge hond zo snel mogelijk kan geleerd worden om wild
                                    te respecteren (of enkel te jagen wanneer ook u wenst te jagen). Het kan ook gebeuren dat uw jonge hond
                                    een dood stuk wild naar u brengt (mogelijks reeds half vergaan). U vindt dit geweldig en beloont
                                    hem/haar uitbundig voor dit prachtige “cadeau”. Straffen is uit den boze want dat gaat compleet tegen
                                    het natuurlijke instinct van een jachthond in.</p>
                                <p>u betrekt uw pup/hond zo veel mogelijk bij alle activiteiten die u onderneemt. Nee, uw hond
                                    moet niet mee naar de supermarkt, maar als u een druk sociaal leven leidt waarbij er voor de hond geen plaats is,
                                    dan moet u zich afvragen of een hond wel iets voor u is. Honden zijn namelijk roedeldieren en hebben dan ook een
                                    sterke behoefte om bij een groep/roedel te horen. In de natuur is dit een roedel met andere honden, maar voor
                                    de gedomesticeerde hond is die roedel uw gezin.</p>
                                <p>er is niet mis mee om initieel verliefd te worden op het ras omwille van het mooie uiterlijk maar na een kennismaking
                                    zal het vooral het karakter zijn dat u dient aan te spreken. U koopt geen pup omwille van zijn speciale uiterlijk,
                                    exclusiviteit of zeldzaamheid en al helemaal niet omdat hij/zij mooi bij uw interieur past. U koopt ook geen
                                    pup als cadeau voor de kinderen: kinderen zijn nu eenmaal kinderen en eens het “nieuwe” er af is, is voor
                                    de meeste kinderen ook de pret eraf en komt de verdere verzorging/opvoeding/training/… op uw schouders terecht.</p>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                            <img
                                alt="Braque Français Type Pyrénées apporteert een fazant"
                                src={require("assets/img/pups-4.webp")}
                            ></img>
                        </Col>
                    </Row>
                    <div className="section-story-overview">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h3 className="title">Beschikbare pups</h3>
                                <p>Bent u na het lezen van bovenstaande nog steeds overtuigd? Dan kan u best contact opnemen met
                                    ons om meer over het ras te weten te komen en eventueel al eens kennis te maken met onze honden.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <DarkFooter />
            </div>
        </>
    );
}

export default Pups;
