import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ANestHeader from "components/Headers/ANestHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function ANest() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over ons eerste nest Braque Français Type Pyrénées pups gefokt uit 2 werk- en schoonheidskampioenen."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <ANestHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Juno du Vallon de Beaudini x Gibus du Domaine de Saint Louis</h2>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p>Dekkingsdatum: 1/2/2017<br />
                  Geboortedatum: 3/4/2017<br />
                  Aantal reuen: 3<br />
                  Aantal teven: 4</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Juno de Vallon de Beaudini, Braque Français Type Pyrénées teef en moeder van ons eerste nest"
                  src={require("assets/img/a-nest-2.webp")}
                ></img>
                <h3 className="title">Juno du Vallon de Beaudini</h3>
                <p>29/5/2014<br />
                  HD-B/HD-A (België/Frankrijk)<br />
                  Frans Werkkampioen najaar (fazant)<br />
                  Belgisch Showkampioen<br />
                  Nederlands Kampioen<br />
                  Internationaal Showkampioen<br />
                  Internationaal Schoonheidskampioen</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="6">
                <img
                  alt="Gibus du Domaine de Saint Louis, Braque Français Type Pyrénées reu en vader van ons eerste nest"
                  src={require("assets/img/a-nest-3.webp")}
                ></img>
                <h3 className="title">Gibus du Domaine de Saint Louis</h3>
                <p>12/7/2011<br />
                  HD-A<br />
                  Frans Werkkampioen voorjaar (patrijs)<br />
                  Frans Werkkampioen najaar (fazant)<br />
                  Zwitsers Werkkampioen<br />
                  Frans Schoonheidskampioen</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="7 pasgeboren pups van het ras Braque Français Type Pyrénées slapend in de werpkist"
                  src={require("assets/img/a-nest-4.webp")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">A nest</h2>
                <h3 className="description">Het avontuur van ons eerste nest</h3>
                <p>De zoektocht naar een geschikte dekreu voor Juno startte al geruime tijd voor Juno uiteindelijk
                  gedekt werd. Reeds begin 2016 begonnen we met het uitpluizen van stambomen, het onderzoeken van verschillende lijnen,
                  het (laten) berekenen van inteeltcoëfficiënten en dergelijke meer.</p>
                <p>Ook een cursus genetica passeerde de revue want als hedendaags fokker is het belangrijk om op de
                  hoogte te zijn van heel wat zaken die hun oorsprong vinden in de genetica zoals bijvoorbeeld
                  genetisch overerfbare aandoeningen, de gevolgen van inteelt/lijnteelt, de gevaren van het zogenaamde
                  “Popular Sire Syndrome” alsook het belang van populatiegenetica waarbij je verder dient te kijken
                  dan enkel de eigen lijn die je als fokker wenst uit te bouwen en dit in het algemene belang van
                  het welzijn van het ras (en niet enkel van je eigen lijn).</p>
                <p>Maar op een bepaald moment kom je natuurlijk op het punt dat je keuzes moet maken en knopen moet doorhakken.
                  Zo beslisten wij dan ook begin 2017 dat Gibus du Domaine de Saint Louis de vader van ons eerste nest zou worden.
                  Gibus is een reu die zowel qua schoonheid als qua werk heeft bewezen over veel goede kwaliteiten te beschikken.
                  En daarnaast is het ook een reu met een heerlijk zacht karakter zoals dat bij een Braque Français hoort te zijn.</p>
                <p>Juno werd sneller loops dan verwacht: half januari werden de eerste tekenen zichtbaar en na enkele progesteron
                  bepalingen was het begin februari zo ver: we contacteerden eventjes Gibus’ eigenaar met een “we komen er aan”
                  en vertrokken richting Disneyland (Gibus woonde daar namelijk niet ver vandaan).</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Moeder Juno du Vallon de Beaudini waakt over haar slapende pups"
                src={require("assets/img/a-nest-5.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Met een gezonde dosis zenuwen arriveerden we op 1 februari nabij Disneyland. Hoe zou de dekking verlopen?
                  Gelukkig werd al snel duidelijk dat Juno en Gibus het prima met elkaar konden vinden en niet veel
                  later kwam er dan ook een mooie, natuurlijke eerste dekking tot stand.</p>
                <p>We hadden besloten dat we haar twee dagen na elkaar zouden laten dekken om zo de kans op succes te vergroten.
                  Op 2 februari werd Juno dan nogmaals succesvol gedekt door Gibus waarna we terug huiswaarts trokken.</p>
                <p>Nu zat er niets anders op dan te wachten. Na een paar weekjes meenden we wel al subtiele gedragsveranderingen
                  en dergelijke te zien maar het was pas toen we een echografie bij de dierenarts lieten uitvoeren dat
                  het duidelijk werd dat Juno écht drachtig was!</p>
                <p>Alles werd dan ook in gereedheid gebracht om een goed maandje later de bevalling en de groei van het
                  nest in goede banen te kunnen leiden: de kantoorruimte in het huis werd omgevormd tot bevallingskamer, de werpkist werd
                  enkele weken voor de bevalling geïnstalleerd zodat Juno er al aan kon wennen (het was echter Horus
                  die er graag in ging liggen) en al het materiaal dat je nodig hebt tijdens en na de bevalling werd
                  aangekocht en klaar gezet.</p>
                <p>Begin april was het dan zover: op 2 april werd Juno ‘s ochtends onrustig en begon ze
                  te rillen en te hijgen. Dat zette zich gans de dag voort totdat Juno net na middernacht aan de eigenlijke bevalling begon!
                  Het werd dan ook een lange nacht waarbij de laatste pup rond 6 uur ‘s ochtends geboren werd.</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Braque Français Type Pyrénées pups verkennen de tuin"
                src={require("assets/img/a-nest-6.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Het was heel mooi om te zien hoe Juno instinctief wist wat er allemaal gedaan moest worden: ze brak zelf het vlies,
                  beet de navelstreng door, likte elke pasgeboren pup droog en liet het vervolgens aan haar tepels de eerste portie
                  melk drinken (zeer belangrijk want deze eerste melk is colostrum wat boordevol afweerstoffen zit die de pup
                  broodnodig heeft vermits deze zelf nog niet over dergelijke stoffen beschikt).</p>
                <p>Na deze lange nacht was het dus zover: Juno’s (en dus ook “ons”) eerste nest was geboren! 3 reutjes en 4 teven zagen het licht!
                  Of tenminste: dat zouden ze hebben gezien als ze dat al konden (pups worden namelijk blind en doof geboren).</p>
                <p>De weken vlogen voorbij: de eerste weken was het vooral mama Juno die al het werk moest doen maar vanaf de 4de week
                  werd het ook voor ons steeds drukker. Dan beginnen we namelijk met de pups af te spenen (wij werken met een combinatie van Farmfood
                  puppymelk, Farmfood Pens&Hart rauw voer en Acana Puppy & Junior brokken). De pups maken daar altijd een smeerboel van
                  én daarenboven stopt de mama dan ook met het opkuisen van de pups hun uitwerpselen waardoor dat dus een (intensief) klusje voor ons werd.</p>
                <p>Bij momenten leek het alsof we constant aan het kuisen waren maar gelukkig waren er voldoende rustmomenten waarin
                  we simpelweg konden genieten van de pups. En toen brak er ook een periode van prachtig warm weer aan waardoor we de
                  pups voor het eerst kennis konden laten maken met de grote buitenwereld (te beginnen met onze tuin).</p>
                <p>Al snel wilden de pups zo goed als gans de dag buiten zijn wat het kuiswerk voor ons toch weer iets gemakkelijker maakte.
                  Dat gezegd zijnde werden de pups zelf steeds actiever en het duurde ook niet lang voordat duidelijk werd dat we een
                  stelletje hyperactieve en super-energieke jachthonden pups in huis hadden!</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Braque Français Type Pyrénées pup op bezoek bij de dierenarts"
                src={require("assets/img/a-nest-7.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Op goed 6 weken trokken we met de hele bende pups naar de dierenarts voor een check-up, eerste vaccinatie,
                  chip én DNA-afname voor de stambomen van de KMSH. Een hele boterham dus voor de pups (en de dierenarts) maar
                  de pups lieten zich goed behandelen door de dierenarts. Ze waren echter minder te spreken over het feit
                  dat ze eventjes met z’n zevenen in een bench moesten!</p>
                <p>En iets later was het dan zover: er was ons al door collega-fokkers gezegd dat die eerste 8 weken voorbij
                  vliegen en dat bleek helaas ook effectief zo te zijn. Voor we het goed en wel beseften, vertrok eerst
                  Qali, dan Qai en meteen de dag daarna Qastor. Een week later was het dan de beurt aan Qharon.</p>
                <p>Querida en Quirina bleven wat langer vermits zij het land verlieten en dus niet voor 15 weken konden vertrekken
                  (ze moeten namelijk hun vaccinatie tegen hondsdolheid hebben ontvangen). Samen met Qetesh, het teefje dat bij ons
                  blijft, stelden zij die laatste weken huis, tuin en omliggend natuurgebied op stelten!</p>
                <p>Maar ook aan het verblijf van Querida en Quirina kwam een eind: Querida vertrok op exact 15 weken naar
                  Zweden en een weekje later vertrok dan ook Quirina naar Zuid-Engeland. Nu bleef Qetesh als enigste over
                  wat natuurlijk even wennen voor haar was maar de positieve keerzijde is dan weer dat nu alle aandacht naar
                  haar uit kon gaan.</p>
                <p>En zo eindigde dan ook het avontuur van ons eerste nest. Nu is het een kwestie van Qetesh op te voeden en te zien hoe zij uitgroeit.
                  Wij hebben er alvast een goed oog op en hopen dat ze in mama’s voetsporen treedt!</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default ANest;
