import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import WerkstandaardHeader from "components/Headers/WerkstandaardHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Werkstandaard() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Een Braque Français Type Pyrénées is een werkhond voor de jacht en er zijn specifieke rastypische verwachtingen aangaande hun werkstijl. Hier leest u er alles over!"
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <WerkstandaardHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Werkstandaard</h2>
                <p>De Braque Français is een hond met een zacht en joviaal karakter, zeer intelligent maar zeker niet geschikt om herleid te worden tot een
                  mechanische robot en ook niet geschikt voor een hardere Spartaanse aanpak.</p>
                <p>Het Pyrenese type heeft een stijl die aanzienlijk afwijkt van die van het Gascogne type.
                  Het kleinere type (zijnde het Pyrenese type) heeft in het algemeen een snelle en stormachtige stijl.
                  Het grote type (de Gascogne) heeft, ten gevolge van zijn zwaardere morfologie, doorgaans een rustiger ogende stijl.</p>
                <p>De Braque Français van het Gascogne type heeft een galop aan gemiddelde snelheid waarbij de absolute snelheid minder
                  belangrijk is dan het vermogen van de hond om zijn snelheid voor langere tijd aan te houden. Wanneer een Gascogne vertrekt,
                  geeft hij de indruk aan een lang parcours te zijn begonnen en niet aan de 100 meter sprint.</p>
                <p>De galop moet soepel en economisch zijn en kan onderbroken worden door een trot wanneer er twijfel bestaat
                  bij het waarnemen van zwakke verwaaiing.</p>
                <p>De galop van het Pyrenese type is sneller in zijn geheel en vertoont een constanter tempo met minder tot geen onderbrekingen.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Jonge Braque Français Type Pyrénées aan het werk"
                  src={require("assets/img/werkstandaard-2.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="title">Veldaanpak</h3>
                <p><b>De hond dient een ruime en open veldaanpak te tonen waarbij veel ruimte wordt genomen</b>; maar de Braque Français,
                  die met zijn intelligentie en karakter werkt, mag nooit de indruk geven getemd of gemechaniseerd te zijn. Wanneer hij
                  vooruit steekt of wanneer hij vertraagt of zelfs dezelfde plek nogmaals voorbijloopt, is dit vaak omdat hij voelt
                  dat hij het risico loopt om een stuk wild voorbij te lopen dan wel op te laten vliegen. Deze karakteristiek,
                  alsook occasionele kortstondige grondcontroles dienen de hond niet kwalijk genomen te worden, juist integendeel.</p>
                <p>De kop dient zo goed als in het verlengde van de ruglijn gehouden te worden. In ieder geval is het niet zo dat deze immobiel
                  is en kan de kop soms net boven of onder de horizontale ruglijn gedragen worden. De hond dient namelijk op zoek te gaan naar
                  waar de verwaaiing zich bevindt afhankelijk van de omstandigheden van het terrein alsook de weercondities.
                  Men krijgt de indruk dat de hond de situatie de baas is. Hij is zeer opmerkzaam en, ten gevolge van het aanpassen
                  van zijn kophouding, kan niets hem ontgaan.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées aan het werk in een bietenveld waar de hond succesvol tot voorstaan is gekomen en een fazant net opvliegt"
                  src={require("assets/img/werkstandaard-3.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="title">Voorstaan</h3>
                <p>Het voorstaan is zeer intens maar niet cataleptisch (de hond bevriest niet). De Braque Français behouden alle aandacht
                  tijdens het voorstaan zodat zij gepast kunnen omgaan met het “coulé”.</p>
                <p><b>De ideale positie voor de Braque Français bij het voorstaan is de staande positie.</b> Het hoofd in de
                  richting waar de verwaaiing vandaan komt, de oren een beetje gespitst, het oog gefixeerd, de staart
                  minder hoog geheven dan bij de Duitse Staande en compleet stil (geen kwispelende staart).</p>
                <p>Als de voorjager ver achterop is, kan het zijn dat de hond zijn kop draait naar de voorjager en vervolgens terug
                  naar het wild alsof de hond de exacte plaats wilt aangeven waar het wild zich bevindt. Op dat moment dient de hond immobiel
                  te zijn en mag hij niet vooruitgaan (couleren) naar het wild tenzij in opdracht van zijn baas.</p>
                <p>Het opstoten van het wild kan min of meer energiek zijn maar is steeds autoritair. In alle gevallen moet de
                  Braque Français gespannen blijven; het gebeurt soms, ondanks een autoritair coulé, dat het wild teveel afstand neemt,
                  de hond kan dan enkele meters spoor lopen alvorens terug directe verwaaiing op te nemen.</p>
                <p>De hond moet steady zijn bij het opvliegen van het wild en dient tevens schotvast te zijn.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées apporteert een fazant"
                  src={require("assets/img/werkstandaard-4.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="title">Apport</h3>
                <p>De wijze waarop dood of gewond wild wordt terug gevonden is veel belangrijker dan de wijze waarop dat
                  wild teruggebracht wordt naar de voorjager.</p>
                <p>Het zoeken kan met de neus hoog en een stevige galop als de verwaaiing sterk en hoog is.
                  Het kan ook in trot of zelfs wandelpas met de neus laag als de verwaaiing zich laag tegen de grond
                  bevindt of als de geur moeilijk waar te nemen is. Een zachte beet is zeer gewenst. De snelheid waarmee
                  de hond terug komt is van beperkt belang maar het apport dient wel vrolijk te zijn
                  (dit mag niet ogen als een zware opdracht voor de hond), zonder dat de hond aan het spelen is weliswaar.</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Werkstandaard;
