import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import RasstandaardHeader from "components/Headers/RasstandaardHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Rasstandaard() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Een Braque Français Type Pyrénées moet aan heel wat voorwaarden qua exterieur en morfologie voldoen om rasconform te zijn. Hier leest u er alles over!"
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <RasstandaardHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Rasstandaard</h2>
                <h3 className="description">Introductie</h3>
                <p>Er zijn 2 types Franse staande honden: het grotere “Gascogne” type en het kleinere “Pyrenese” type.
                  Beide types komen oorspronkelijk uit Zuidwest Frankrijk en de centrale Pyreneeën.</p>
                <p>De standaard van het Pyrenese type is afgeleid van die van het Gascogne-type waardoor wij eerst deze standaard beschrijven.
                  Vervolgens volgen dan de punten waarin het Pyrenese type hier van afwijkt.</p>
                <h4 className="description">Braque Français Type Gascogne</h4>
                <p>Staande hond met medium proporties van het “Braque” type, nobel voorkomen, krachtig maar zonder overdreven zwaarheid,
                  robuust met sterke beenderstructuur. De teven zijn fijner. De skin is soepel en redelijk los. Lengte van de snuit iets korter dan die van de schedel.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées puppy"
                  src={require("assets/img/rasstandaard-juno-pup.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Hoofd</h5>
                <p>Vrij belangrijk maar niet te zwaar. In profiel zijn de lijnen van de schedel en van de snuit iets afwijkend.</p>
                <h6 className="description">Craniale regio</h6>
                <p>Schedel: Bijna vlak of heel licht afgerond, toont een medium groef die slechts licht gemarkeerd is.
                  De achterhoofdsknobbel is nauwelijks prominent.</p>
                <p>Stop: Noch ondiep, noch geaccentueerd.</p>
                <h6 className="description">Aangezicht</h6>
                <p>Neus: Breed, bruin in kleur, neusgaten goed geopend.</p>
                <p>Snuit: Breed en rechthoekig, soms een beetje convex.</p>
                <p>Lippen: Hangend, de wangen zijn redelijk gerimpeld.</p>
                <p>Kaken/tanden: Moeten compleet zijn. Tanggebit wordt getolereerd.</p>
                <p>Ogen: Goed open en goed geplaatst in de oogkas, kleur bruin of donkergeel. De blik is vrank.</p>
                <p>Oren: Medium lengte, aansluiting aan het hoofd ter hoogte van de ooglijn, niet te breed aan de basis, kaderen het hoofd goed,
                  licht gevouwen en aan het uiteinde afgerond. Eén of twee verticale lijnen kunnen bestaan ter hoogte van de kaak en een beetje
                  boven de aansluiting van het oor. De uiteinden van de oren moeten tot de achterkant van de neus reiken.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées puppy met 2 kinderen"
                  src={require("assets/img/rasstandaard-juno-pup-2.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Lichaam</h5>
                <p>Nek: Van goede lengte, licht gebogen in het bovenste gedeelte, altijd met een lichte keelhuid.</p>
                <p>Rug: Breed, recht, soms een beetje lang maar steeds goed ondersteund.</p>
                <p>Lendenen: Kort, gespierd, licht gebogen.</p>
                <p>Kruis: Iets schuin in relatie tot de bovenlijn.</p>
                <p>Borst: Breed van voren gezien, lang in profiel, reikend tot aan het niveau van de elleboog,de ribben zijn afgerond zonder overdrijving.</p>
                <p>Buik: Vlak. Buik slechts licht opgetrokken.</p>
                <p>Staart: Over het algemeen gecoupeerd en de kromming van de lijn van de romp verder zettend. De lange staart is,
                  indien goed gedragen, geen fout net als de van nature korte staart.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées puppy op de schoot van een man"
                  src={require("assets/img/rasstandaard-juno-pup-3.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Ledematen</h5>
                <h6 className="description">Voorhand</h6>
                <p>Algemeen voorkomen: Benen verticaal, recht en gespierd.</p>
                <p>Schouders: Zeer gespierd en matig schuin.</p>
                <p>Opperarm: Sterk en goed gespierd.</p>
                <p>Ellebogen: Op het niveau van het borstbeen.</p>
                <p>Voorvoeten: Tenen zijn strak en goed gebogen, vormen een compact geheel, bijna rond. De nagels zijn sterk en de kussens dik en stevig.</p>
                <h6 className="description">Achterhand</h6>
                <p>Algemeen voorkomen: Verticaal en recht.</p>
                <p>Dijen: Vlezig, gespierd.</p>
                <p>Benen: Gespierd.</p>
                <p>Hakken: Matig gehoekt.</p>
                <p>Middenvoet: Tamelijk kort.</p>
                <p>Voeten: Compact, bijna rond.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="2 honden van het ras Braque Français Type Pyrénées, links een reu en rechts een teef"
                  src={require("assets/img/rasstandaard-4.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Vacht</h5>
                <h6 className="description">Haar</h6>
                <p>Vrij dik en goed voorzien. Fijner op het hoofd en de oren.</p>
                <h6 className="description">Kleur</h6>
                <p>1 van de volgende:</p>
                <p>Kastanjebruin.<br />
                  Kastanjebruin en wit.<br />
                  Kastanjebruin en sterk gespikkeld wit.<br />
                  Kastanjebruin, gemarkeerd met tan (boven de ogen, aan de lippen en op de poten).<br />
                </p>
                <h5 className="description">Grootte</h5>
                <p>Reuen: 60 tot 69 cm.</p>
                <p>Teven: 58 tot 68 cm.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Jonge hond van het ras Braque Français Type Pyrénées"
                  src={require("assets/img/rasstandaard-5.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Fouten</h5>
                <p>Elke afwijking van de voorgaande en hieronder opgelijste punten moet worden beschouwd als een fout.
                  De ernst waarmee de fout moet worden beschouwd moet in verhouding staan tot de omvang en het effect ervan op de
                  gezondheid en het welzijn van de hond.</p>
                <p>Hond te zwaar of te licht.<br />
                  Achterhoofdsknobbel te uitgesproken.<br />
                  Lippen te dik of niet hangend genoeg.<br />
                  Ogen te rond veroorzaakt door overdrijving van het jukbeen en wenkbrauwbogen.<br />
                  Oren te vlak en te kort, of te gekruld.<br />
                  Buik te opgetrokken (windhond type).<br />
                  Spreidvoeten.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées puppy"
                  src={require("assets/img/rasstandaard-6.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Diskwalificerende fouten</h5>
                <p>Agressief of te schuw.<br />
                  Elke hond die duidelijk lichamelijke of gedragsafwijkingen toont, zal worden gediskwalificeerd.<br />
                  Schedel/snuit te convergerende lijnen of juist té uiteenlopende lijnen.<br />
                  Gespleten neus, uitgesproken depigmentatie.<br />
                  Over- of onderbeet.<br />
                  Entropion, ectropion, roze vlekken op de oogleden.<br />
                  Afwezigheid van de staart.<br />
                  Syndactylie (tenen samen opgegroeid), overtollige tenen, afwezigheid van de tenen.</p>
                <p>De reuen moeten twee normale testikels hebben die volledig ingedaald zijn in het scrotum.</p>
                <p>Alleen functioneel en klinisch gezonde honden, met rastypische conformatie mogen in de fok ingezet worden.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées puppy zittend op haar achterpoten"
                  src={require("assets/img/rasstandaard-7.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h4 className="description">Braque Français Type Pyrénées</h4>
                <p>Het kleinere type Franse staande hond, het Pyrenese type, behoudt alle proporties van het Gascogne type maar dan met
                  kleinere dimensies en lichtere vormen. Ze wijken enkel af op de hieronder vermelde punten.</p>
                <p>Rustieke hond, niet zwaar, maar voldoende gespierd. Huid strakker dan die van het Gascogne type.</p>
                <p>Neus: Kastanjebruin, de neusgaten zijn goed geopend.</p>
                <p>Lippen: De lippen zijn minder hangend of minder convex dan bij het Gascogne type.</p>
                <p>Oren: Boven de ooglijn ingevoegd, nauwelijks gevouwen. De uiteinden van de oren moeten 2 cm van de neus stoppen.</p>
                <p>Geringe of geen keelhuid.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Braque Français Type Pyrénées rennend naast een meer"
                  src={require("assets/img/rasstandaard-8.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Lichaam</h5>
                <p>Buik: Buiklijn minder neergaand dan bij het Gascogne type.</p>
                <p>Staart: Fijn, kan gecoupeerd zijn of natuurlijk kort.</p>
                <h5 className="description">Ledematen</h5>
                <h6 className="description">Voorhand</h6>
                <p>Algemeen voorkomen: Ze zijn lichter dan bij het Gascogne type.</p>
                <p>Schouders: Gespierd en matig schuin.</p>
                <p>Voorvoeten: Strak.</p>
                <h6 className="description">Achterhand</h6>
                <p>Voeten: Strak.</p>
                <h5 className="description">Vacht</h5>
                <p>Haar: Dunner en korter dan dat van het Gascogne type.</p>
                <h5 className="description">Grootte</h5>
                <p>Reuen: 51 tot 58 cm.</p>
                <p>Teven: 49 tot 56 cm.</p>
              </Col>
            </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="Volwassen teef van het ras Braque Français Type Pyrénées"
                  src={require("assets/img/rasstandaard-9.webp")}
                ></img>
              </Col>
            </Row>
            <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h5 className="description">Fouten</h5>
                <p>Elke afwijking van de voorgaande en hieronder opgelijste punten moet worden beschouwd als een fout.
                  De ernst waarmee de fout moet worden beschouwd moet in verhouding staan tot de omvang en het effect ervan op de
                  gezondheid en het welzijn van de hond.</p>
                <p>Hond te zwaar of te licht.<br />
                  Lippen te dik of niet hangend genoeg.<br />
                  Ogen te rond veroorzaakt door overdrijving van het jukbeen en wenkbrauwbogen.<br />
                  Oren die aansluiten op ooghoogte en/of te lang zijn (raken de neus)<br />
                  Buik te opgetrokken (windhond type).<br />
                  Spreidvoeten.</p>
                <h5 className="description">Diskwalificerende fouten</h5>
                <p>Agressief of te schuw.<br />
                  Elke hond die duidelijk lichamelijke of gedragsafwijkingen toont, zal worden gediskwalificeerd.<br />
                  Gespleten neus, uitgesproken depigmentatie.<br />
                  Over- of onderbeet.<br />
                  Entropion, ectropion, roze vlekken op de oogleden.<br />
                  Afwezigheid van de staart.<br />
                  Syndactylie (tenen samen opgegroeid), overtollige tenen, afwezigheid van de tenen.</p>
                <p>De reuen moeten twee normale testikels hebben die volledig ingedaald zijn in het scrotum.</p>
                <p>Alleen functioneel en klinisch gezonde honden, met rastypische conformatie mogen in de fok ingezet worden.</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Rasstandaard;
