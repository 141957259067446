import {Helmet} from "react-helmet";

export default function SEO({ title, description, type }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />                    
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <link rel="manifest" href={process.env.PUBLIC_URL + "/manifest.json"} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="Senteurs du Ciel" />
            <meta property="og:locale" content="nl_BE" />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:site" content="@steve_vdbuys" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={process.env.PUBLIC_URL + "/index-team.webp"} />
            { /* End Twitter tags */}
            { /* Favicon links */}
            <link rel="icon" type="image/png" href={process.env.PUBLIC_URL + "/favicon-16x16.png"} sizes="16x16" />
            <link rel="icon" type="image/png" href={process.env.PUBLIC_URL + "/favicon-32x32.png"} sizes="32x32" />
            <link rel="icon" type="image/png" href={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} sizes="192x192" />
            <link rel="icon" type="image/png" href={process.env.PUBLIC_URL + "/android-chrome-512x512.png"} sizes="512x512" />
            <link rel="apple-touch-icon" href={process.env.PUBLIC_URL + "/apple-touch-icon.png"} />
            { /* End Favicon links */}
        </Helmet>
    )
}