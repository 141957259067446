import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import QeteshHeader from "components/Headers/QeteshHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Qetesh() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over Qetesh des Senteurs du Ciel, het Braque Français Type Pyrénées teefje dat we uit ons eerste nest gehouden hebben."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <QeteshHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Qetesh des Senteurs du Ciel</h2>
                <h3 className="description">In resultaten</h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Veldwerk</h4>
                <h5 className="description">Titels</h5>
                <p>Frans werkkampioen op geschoten wild</p>
                <h5 className="description">CACIT</h5>
                <h6 className="description">Internationaal kampioenschapspunt</h6>
                <p>1 CACIT (België, patrijs)</p>
                <h5 className="description">CACT/RCACT</h5>
                <h6 className="description">Nationaal kampioenschapspunt</h6>
                <p>1 CACT (België, patrijs)<br />
                  1 CACT (Frankrijk, fazant)<br />
                  3 RCACT (Frankrijk, fazant)</p>
                <h5 className="description">Uitmuntend</h5>
                <p>Multi-Uitmuntend in FTP (Frankrijk)<br />
                  Multi-Uitmuntend in FTGT (Frankrijk)<br />
                  Multi-Uitmuntend in België</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <img
                  alt="Qetesh des Senteurs du Ciel, Braque Français Type Pyrénées teef wordt gepresenteerd op een schoonheidswedstrijd"
                  src={require("assets/img/qetesh-2.webp")}
                ></img>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Schoonheid</h4>
                <h5 className="description">Titels</h5>
                <p>Belgisch Jeugdkampioen 2018<br />
                  World Winner 2018<br />
                  Belgian Winner 2018</p>
                <h5 className="description">CACIB</h5>
                <h6 className="description">Internationaal kampioenschapspunt</h6>
                <p>1 (Nederland)<br />
                  1 (België)</p>
                <h5 className="description">CAC</h5>
                <h6 className="description">Nationaal kampioenschapspunt</h6>
                <p>2 (Nederland)<br />
                  2 (België)<br />
                  1 (Frankrijk)</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Qetesh des Senteurs du Ciel</h2>
                <h3 className="description">In woord en beeld</h3>
                <h4 className="description">Onze eerste generatie</h4>
                <h5 className="description">Waarom we Qetesh hebben gekozen</h5>
                <p>Vermits het A nest ons eerste nest was, was het al van in het begin ook de bedoeling dat
                  er één teefje bij ons zou blijven om zo de eerste generatie onder onze eigen kennelnaam
                  te vertegenwoordigen. Maar met 4 teefjes in het nest werd natuurlijk al snel de
                  vraag welk teefje dat dan moest zijn.</p>
                <p>Quirina viel redelijk snel af omdat ik daar simpelweg zelf geen echte “connectie” mee had en
                  dat was zeker wel een vereiste vermits een goede band met je hond de basis van alles is.</p>
                <p>Qetesh trok aanvankelijk eigenlijk ook niet meteen mijn aandacht. Het waren Qali en Querida
                  die in eerste instantie mijn hart veroverden. Qali met haar heerlijk gekke karakter en het “lef” om
                  uit te pakken tegen Horus. En Querida omdat zij als kleinste van het nest een sterk karakter had
                  ontwikkeld waardoor ze zich uitstekend staande wist te houden tussen haar grotere broers en zussen
                  (overigens zou Querida later uitgroeien tot de grootste teef van het nest).</p>
                <p>Het was pas tijdens de eerste testen met wild dat Qetesh zich ontpopte als een pup met wel heel
                  veel interesse in dat wild. Qali was tijdens die testen eerder wat terughoudend waardoor zij
                  toen ook afviel (maar dat ik er een sterke band mee had was duidelijk toen ik haar met haar
                  nieuwe eigenaars moest meegeven want dat deed toch wel even pijn).</p>
                <p>Querida bleek echter ook fel te zijn op wild en dus was de keuze nog niet finaal. Het was
                  pas na meerdere testen en het evalueren van de bouw (in zoverre je dat al kan met pups van
                  slechts 7 à 8 weken) dat de keuze uiteindelijk voor Qetesh viel. En dat is een keuze die ik
                  me tot op heden nog niet beklaagd heb!</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Qetesh des Senteurs du Ciel, Braque Français Type Pyrénées puppy, spelenderwijs presenteren zodat we haar morfologie kunnen beoordelen"
                src={require("assets/img/qetesh-3.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>Qetesh werkt graag in het veld en heeft ondertussen bewezen een uitstekende hond voor het veldwerk te zijn. 
                  De passie om wild te vinden is zeer sterk aanwezig en ze heeft een uitstekende neus die haar in staat stelt om 
                  ook in moeilijkere omstandigheden kleiner gevogelte zoals patrijs te vinden en vast te zetten. De goede band 
                  waarover ik eerder schreef, maakt ook dat ze graag samen met mij werkt en dus ook (meestal) onder controle blijft. 
                  Iets wat met mama Juno wel al eens anders was en voor mij dus zeker een verbetering is want samen jagen is 
                  natuurlijk de boodschap.</p>
                  <p>Ook qua exterieur scoort Qetesh uitstekend. Ze is reeds Belgisch Jeugdkampioen en is zelfs al twee keer in de 
                    erering geselecteerd als één van de mooiste staande jachthonden op show. Het absolute hoogtepunt is echter 
                    Qetesh haar deelname aan de World Dog Show 2018 waar ze op de leeftijd van 16 maanden World Winner 2018 werd!</p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Een portretfoto van Qetesh des Senteurs du Ciel, Braque Français Type Pyrénées"
                src={require("assets/img/qetesh-4.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="description">Diva én jachthond</h3>
                <p>De Covid-19 pandemie gooide onze plannen aardig in de war want tijdens de lockdowns werd het plots
                  ook onmogelijk om in Frankrijk te gaan trainen en veldwedstrijden werden ook in eigen land geannuleerd. Dat
                  was uiterst vervelend want hoewel ik wel een goed oog op de kwaliteiten van Qetesh had, ben ik van mening 
                  dat dat ook moet bewezen worden op de wedstrijden.</p>
                <p>Het was wachten tot in 2022 om Qetesh eindelijk te zien schitteren op de veldwedstrijden met als hoogtepunt 
                  de homologatie van haar titel "Werkkampioen op geschoten wild" in Frankrijk! Graag had ik haar ook nog een 
                  extra schoonheidstitel bezorgd voor we aan een nestje begonnen. Ik had haar dan ook ingeschreven voor de Brussels Dog Show 
                  in het najaar maar kreeg net voor die wedstrijd zelf Covid-19 waardoor ik niet aanwezig kon zijn. Niet 
                  veel later werd Qetesh loops en vermits Qetesh reeds 5,5 jaar is, was een nest voor ons belangrijker dan die 
                  titel!</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Qetesh;
